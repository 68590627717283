body {
    background-color: #ffffff; /* Black background */
    color: #aaaaaa; /* Light gray text color */
    font-family: Arial, sans-serif;
    /* margin: 0;
    padding: 0; */
  }
  
  .terms-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    margin-top: 100px;
    /* margin: 0 auto; */
    background-color: #ffffff; /* Slightly lighter black for contrast */
    border-radius: 10px;
  }
  
  h1 {
    color: #df5a00; /* Orange */
    font-size: 2em;
    margin-bottom: 20px;
    text-align: center;
  }
  
  h2 {
    color: #ff6600; /* Lighter orange */
    font-size: 1.5em;
    margin-top: 20px;
  }
  
  p {
    color: #000000;
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  .disclaimer {
    font-size: 0.9em;
    color: #ff6600;
    margin-top: 30px;
    text-align: center;
  }
  